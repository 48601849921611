.controls-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.control-button {
  border-radius: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #1C1C15;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}

.control-button:hover {
  background: rgba(255, 255, 255, 0.047);
}