#speed-range {
  width: 50px;
}

.speed-label {
  margin-top: 10px;
  padding: 0 10px;
}

.slider-container {
  display: flex;
  align-items: center;
}