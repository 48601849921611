.simulator-container {
  height: 50%;
  position: relative;
  border-radius: 50px;
  cursor: pointer;
  touch-action: none;
}

.recording-label-container {
  position: absolute;
  z-index: 4;
  top: -40px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

}

.simulator-label-container {
  position: absolute;
  top: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.simulator-label {
  height: 50%;
  aspect-ratio: 1;
  width: 50%;
  height: 50%;
  background-color: rgba(63, 63, 63, 0.784);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.473);
  font-size: 50px;
}

.simulator-container.playing {
  background: rgba(13, 76, 39, 0.458);
  cursor: none;
}

.simulator-container.recording {
  background: rgba(185, 40, 40, 0.352);
  cursor: none;
}

.simulator-container.iddle {
  background: transparent;
}

.simulator-container.iddle:hover {
  background: rgba(208, 208, 208, 0.052);
}

.machine-image {
  height: 100%;
}

.slider-image {
  height: 50%;
  z-index: 2;
  position: absolute;
  left: 36%;
}

.recording-dot {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background: rgb(176, 1, 1);
  margin-right: 10px;
}