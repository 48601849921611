.connect-button-link {
  cursor: pointer;
}

.connect-button-container {
  margin-top: 5px;
  width: 180px;
  height: 50px;
  border-radius: 7px;
  background-color: #5B5D4D;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-button-container:hover {
  background-color: #b0b0b0;
}

.connect-button-icon {
  margin-right: 10px;
}

.connect-button-text {
  font-weight: 800;
}