.dialog-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.817);
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-container {
  width: 94%;
  max-width: 500px;
  padding: 15px;
  min-height: 200px;
  border-radius: 7px;
  background: #2c5364;
  color: white;
}

.dialog-container-centered {
  width: 94%;
  max-width: 500px;
  padding: 15px;
  min-height: 200px;
  border-radius: 7px;
  background: #2c5364;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dialog-container-centered button {
  z-index: 3;
}

.dialog-container h2 {
  margin: 0 0 10px 0;
}


.dialog-container p {
  margin: 0 0 20px 0;
}

.button-container {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dialog-bg button.btn-primary {
  margin-left: 10px;
  background-color: rgb(153, 172, 53);
  border: solid 1px rgb(153, 172, 53);
}

.dialog-bg button:hover {
  background-color: rgb(168, 175, 128);
  border: solid 1px rgb(153, 172, 53);
}

.loading-animation-container {
  height: 200px;
  width: 200px;
}

.success-animation-container {
  position: absolute;
  top: 0;
}