.main-screen-container {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  touch-action: none;
  overflow: hidden;
}

.spacer {
  flex: 2;
}

.title-container {
  width: 94%;
  padding: 3%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.title-container h5 {
  margin: 0;
}

.title-container h1 {
  margin: 0;
}

.top-menu {
  position: absolute;
  top: 0;
  right: 0;
  margin: 3%;
  cursor: pointer;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.help-button {
  margin-top: 5px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #5B5D4D;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-button:hover {
  background-color: #7e816b;

}