.timeline-container {
  width: 94%;
  margin: 3%;
  height: 10%;
  background-color: rgba(33, 33, 26);
  border-radius: 5px;
  position: relative;
}

.mouse-shadow-line {
  width: 1px;
  height: 100%;
  background: rgba(255, 255, 255, 0.27);
  position: absolute;
}

.current-t-label {
  position: absolute;
  font-size: 12px;
  color: rgb(153, 172, 53);
  top: -18px;
}

.shadow-t-label {
  position: absolute;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.27);
  top: -18px;
}

.duration-label-container {
  height: 40px;
  position: absolute;
  font-size: 12px;
  color: white;
  top: -40px;
  right: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.duration-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 18px;
  top: -35px;
  right: 0px;
}

.duration-input-container input {
  width: 70px;
  text-align: center;

}

.duration-input-container img {
  cursor: pointer;
  padding: 2px;
  border-radius: 10px;

}

.duration-input-container img:hover {
  background-color: rgba(255, 255, 255, 0.196);
}

.current-t-line {
  width: 1px;
  height: 100%;
  background: rgb(153, 172, 53);
  position: absolute;
}

.duration-set-badge {
  cursor: pointer;
  background: rgb(153, 172, 53);
  border-radius: 5px;
  border-bottom-right-radius: 0;
  padding: 3px;
  font-weight: 800;
}