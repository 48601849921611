html {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  overscroll-behavior-y: none;
}

#root {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #0f2027 0%, #203a43 50%, #2c5364 100%);
  height: 100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}